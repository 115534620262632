var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"panels","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-magnify","title":'Pencarian Surat: ' + _vm.skey}},[_c('v-data-table',{staticClass:"elevation-1 px-5 py-3",attrs:{"headers":_vm.headers,"items":_vm.listDataSearch,"loading":_vm.listLoading},scopedSlots:_vm._u([{key:"item.searchlist",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.noSurat)}}),_c('br'),_c('span',[_vm._v(_vm._s(item.perihalSurat))]),_c('br')]}},{key:"item.tglSurat",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tglSurat)))])]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.showIcon && item.filescan)?_c('v-btn',_vm._g({attrs:{"color":"blue","fab":"","x-small":"","text":""},on:{"click":function($event){return _vm.viewProcess(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Buka Surat")])])]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.toast.timeout},model:{value:(_vm.toast.show),callback:function ($$v) {_vm.$set(_vm.toast, "show", $$v)},expression:"toast.show"}},[_vm._v(" "+_vm._s(_vm.toast.text)+" "),_c('v-btn',{attrs:{"color":_vm.toast.color,"text":""},on:{"click":function($event){_vm.toast.show = false}}},[_vm._v(" Close ")])],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('v-container',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-card',{staticClass:"pa-md-2 mx-lg-auto",attrs:{"width":"70vW","outlined":""}},[_c('v-list-item',{attrs:{"three-line":"","centered":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v("Processing")]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v("Please Wait....")]),_c('v-list-item-subtitle',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }